// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import LoginController, { Props } from "./LoginController.web";

class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box height="100%">
          <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <RedButton data-test-id={"btnLogin"} onClick={this.onLogin}>
              <Typography>Login with hubSpot</Typography>
            </RedButton>
          </Box>
        </Box>
      </>
    );
  }
}

const RedButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  backgroundColor: "#f94b4b",
  color: "white",
  padding: "9px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:375px)": {
    width: "100%",
  }
});

export default Login;
// Customizable Area End