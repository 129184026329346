import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import AbusedetectorController, {
  Props,
  configJSON,
} from "./AbusedetectorController";

export default class Abusedetector extends AbusedetectorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={"xs"}>
        <Box sx={webStyle.mainWrapper}>
          <Box sx={webStyle.headerStyle} >
            <Typography variant="h6">BxBlockAbuseDetector</Typography>
          </Box>
          <Box sx={webStyle.imageWrapper}>
          <img
            src="https://www.shutterstock.com/shutterstock/photos/2094111787/display_1500/stock-vector-leaders-are-always-readers-book-lovers-quote-typography-design-perfect-design-for-book-readers-2094111787.jpg"
            alt="post image"
            width="100%"
            height="100%" />
          </Box>
          <Box sx={webStyle.inputStyle}>
            <Input
              data-test-id={"txtInput"}
              type="text"
              placeholder="Add a comment..."
              fullWidth={true}
              disableUnderline={true}
              value={this.state.txtInputValue}
              onChange={(event) => this.setInputValue(event.target.value)}
              onKeyDown={this.onKeyDownHandler}
              style={webStyle.inputInner}
            />
          </Box>
          <Box
            data-test-id="btnAddExample"
            onClick={() => this.commentButtonPressed()}
            component="button"
            sx={webStyle.buttonStyle}
          >
            <Button style={webStyle.innerButton}>Comment</Button>
          </Box>
          <Box>
            {this.state.allAddedComments.map((comment: string, index: number) => {
              return <Typography style={webStyle.commentStyle} key={index}>{comment}</Typography >
            })}
          </Box>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  imageWrapper : {
    width: "100%",
    padding: "5px 0 3px",
  },
  inputStyle: {
    marginTop: "20px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    padding: "5px 0 3px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputInner: {
    fontSize: "14px"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "30px",
    border: "none",
    borderRadius: "25px",
    backgroundColor: "rgb(98, 0, 238)",
    cursor: "pointer"
  },
  innerButton: {
    color: "#fff"
  },
  headerStyle: {
    textAlign: "center"
  },
  commentStyle : {
    padding: "5px 0"
  }
};
// Customizable Area End
