import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { FormikProps } from "formik";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phoneNumber: boolean;
  company: boolean;
  website: boolean;
  lifeCycleStage: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  website: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  website: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class Crm3rdpartyintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formikRef: FormikProps<Error> | null = null;
  addContactApiCallId: string = "";
  getNewAuthTokenApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      website: ""
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.category === "EXPIRED_AUTHENTICATION") {
          this.getNewAuthToken();
        } else if (responseJson.message) {
          toast.error(responseJson.message);
        } else if (apiRequestCallId === this.addContactApiCallId) {
          toast.success("Contact information added successfully!");
          this.setState({ firstName: "", lastName: "", email: "", phoneNumber: "", website: "", company: "" });
          this.formikRef && this.formikRef.resetForm({ values: undefined });
        } else if (apiRequestCallId === this.getNewAuthTokenApiCallId) {
          if (responseJson.access_token) {
            localStorage.setItem("accessToken", responseJson.access_token);
            this.addContactInformation();
          }
        }
      }
    }
    // Customizable Area End
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  }

  addContactInformation = () => {
    const header = {
      "Content-Type": configJSON.addContactApiContentType,
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    };

    const httpBody = {
      "properties": {
        "email": this.state.email,
        "firstname": this.state.firstName,
        "lastname": this.state.lastName,
        "phone": this.state.phoneNumber,
        "company": this.state.company,
        "website": this.state.website
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addContactApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNewAuthToken = () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const header = {
      'Content-Type': configJSON.getTokenApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewAuthTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      `grant_type=refresh_token&client_id=${configJSON.clientId}&client_secret=${configJSON.client}&redirect_uri=${configJSON.baseUrl}${configJSON.redirectUrl}&refresh_token=${refreshToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hubSpotUrl}${configJSON.getTokenApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}