Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "abusedetector";
exports.labelBodyText = "abusedetector Body";

exports.btnExampleTitle = "CLICK ME";
exports.endpoint = "bx_block_comments/comments";
exports.baseUrl = "https://newsaggregator-305401-ruby.b305401.dev.eastus.az.svc.builder.cafe";
exports.abuseDetectorAPIMethod = "POST"
// Customizable Area End