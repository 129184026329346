// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
  }

  // Customizable Area Start
  onLogin = () => {
    window.open(`${configJSON.loginUrl}?client_id=${configJSON.clientId}&redirect_uri=${configJSON.baseUrl}${configJSON.redirectUrl}&scope=${configJSON.scope}`, "_self");
  }
  // Customizable Area End
}