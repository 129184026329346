Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "crm3rdpartyintegration";
exports.labelBodyText = "crm3rdpartyintegration Body";

exports.btnExampleTitle = "CLICK ME";

exports.hubSpotUrl = "https://api.hubapi.com";
exports.loginUrl = "https://app.hubspot.com/oauth/authorize";
exports.baseUrl = "https://newsaggregator-305401-react.b305401.dev.eastus.az.svc.builder.cafe";
exports.redirectUrl = "/middle";
exports.scope = "oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.lists.write";
exports.addContactApiContentType  = "application/json";
exports.getTokenApiContentType  = "application/x-www-form-urlencoded";
exports.postMethod = "POST";
exports.addContactApiEndPoint = "/bx_block_crm_api/contacts";
exports.getTokenApiEndPoint = "/oauth/v1/token";
exports.clientId = "d0346e93-7c75-4c13-8de0-08c22a7dda80";
exports.client = "9fdd46c8-07d1-45f9-9279-c0a10c39a1b6";
// Customizable Area End