// Customizable Area Start
import React from "react";
import {
  Box,
  CircularProgress
} from "@material-ui/core";
import MiddleController, { Props } from "./MiddleController.web";

class Middle extends MiddleController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box height="100%">
          <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </Box>
      </>
    );
  }
}

export default Middle;

// Customizable Area End