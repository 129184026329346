// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputBase,
  Grid
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Crm3rdpartyintegrationController, { Props, Touched, Error } from "./Crm3rdpartyintegrationController.web";

class Crm3rdpartyintegration extends Crm3rdpartyintegrationController {
  constructor(props: Props) {
    super(props);
  }

  contactInfoSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required("*First name is required")
        .matches(/^[A-Za-z]*$/, "*Enter valid first name"),
      lastName: Yup.string().required("*Last name is  required")
        .matches(/^[A-Za-z]*$/, "*Enter valid last name"),
      email: Yup.string().required("*Email is required")
        .email("*Enter valid email"),
      phoneNumber: Yup.string().required("*Phone number is required")
        .min(12, "*Enter valid phone number"),
      company: Yup.string().required("*Company name is required"),
      website: Yup.string().url("*Enter valid url").required("*Website url is required")
    });
  }

  getErrorMessage = (touched: FormikTouched<Touched>, errors: FormikErrors<Error>, value: string) => {
    return touched[value as keyof Error] && errors[value as keyof Error] &&
      <Typography style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}>{errors[value as keyof Error]}</Typography>
  }

  render() {
    return (
      <>
        <Box display="flex" justifyContent="center" mt="60px">
          <Formik
            data-test-id="formik"
            innerRef={(refs) => (this.formikRef = refs)}
            validationSchema={this.contactInfoSchema}
            initialValues={{
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              email: this.state.email,
              phoneNumber: this.state.phoneNumber,
              company: this.state.company,
              website: this.state.website
            }}
            onSubmit={(values) => {
              this.addContactInformation();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Box width="80%" margin="auto" marginTop="60px">
                  <Box width="100%">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ marginBottom: "5px" }}>
                          First name<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          data-test-id="txtInput1"
                          placeholder="First name"
                          value={this.state.firstName}
                          onChange={(event) => {
                            setFieldValue("firstName", event.target.value);
                            this.onValueChange("firstName", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "firstName")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ marginBottom: "5px" }}>
                          Last name<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          data-test-id="txtInput2"
                          placeholder="Last name"
                          value={this.state.lastName}
                          onChange={(event) => {
                            setFieldValue("lastName", event.target.value);
                            this.onValueChange("lastName", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "lastName")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ marginBottom: "5px" }}>
                          Email<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          data-test-id="txtInput3"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                            this.onValueChange("email", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "email")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ marginBottom: "5px" }}>
                          Phone number<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          data-test-id="txtInput4"
                          placeholder="Phone number"
                          value={this.state.phoneNumber}
                          onChange={(event) => {
                            const regex = /^[0-9\b]+$/;
                            if ((event.target.value === '' || regex.test(event.target.value)) && event.target.value.length <= 12) {
                              setFieldValue("phoneNumber", event.target.value);
                              this.onValueChange("phoneNumber", event.target.value);
                            }
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "phoneNumber")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ marginBottom: "5px" }}>
                          Company name<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          data-test-id="txtInput5"
                          placeholder="Company name"
                          value={this.state.company}
                          onChange={(event) => {
                            setFieldValue("company", event.target.value);
                            this.onValueChange("company", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "company")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ marginBottom: "5px" }}>
                          Website url<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          data-test-id="txtInput6"
                          placeholder="Website url"
                          value={this.state.website}
                          onChange={(event) => {
                            setFieldValue("website", event.target.value);
                            this.onValueChange("website", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "website")}
                      </Grid>
                    </Grid>
                    <Box mt="30px" display="flex" justifyContent="center">
                      <RedButton type="submit">
                        <Typography>Submit</Typography>
                      </RedButton>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </>
    );
  }
}

export default Crm3rdpartyintegration;

const CustomInputBase = styled(InputBase)({
  borderRadius: "10px",
  minHeight: "45px",
  border: "1px solid #787878",
  paddingLeft: "15px",
  fontSize: "16px",
  '& .MuiInputBase-inputMultiline': {
    "&::placeholder": {
      fontSize: "17px",
    }
  }
});

const RedButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  backgroundColor: "#f94b4b",
  color: "white",
  padding: "9px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:375px)": {
    width: "100%",
  }
});

// Customizable Area End