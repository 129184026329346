import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordVisible as imagePasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  allAddedComments:  string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AbusedetectorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  detectAbuseAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      allAddedComments: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (this.detectAbuseAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ allAddedComments: [ ...this.state.allAddedComments, apiResponse.data.attributes.comment], txtInputValue: "" })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  buttonShowHideImageProps = {
    source: imagePasswordVisible
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area End

  // Customizable Area Start
  isEmptyOrSpaces = (value:string) => {
    return value === null || value.match(/^ *$/) !== null;
  }

  commentButtonPressed = () => {
    if(!this.isEmptyOrSpaces(this.state.txtInputValue)){
      this.detectAbuseMethod();
    }
  }

  onKeyDownHandler = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      this.commentButtonPressed();
    }
  };

  detectAbuseMethod = () => {
    const header = {
      "Content-Type": "application/json",
      "token": "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzY0LCJleHAiOjE2ODMxOTY5ODEsInRva2VuX3R5cGUiOiJsb2dpbiJ9.Qrm40kBKIj6kO-AU2eZK1e14IWps7Ey2PM6_26A78kbISk7s6RVheSJ9i14poi55HZENNrVN1YWJPgOHpTzwUg"
    };

    const httpBody = {
      "comment": {
        "comment": this.state.txtInputValue,
        "commentable_id": 1,
        "commentable_type": "BxBlockComments::Comment"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.detectAbuseAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.abuseDetectorAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
