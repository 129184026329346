import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase';
import Portal from '@material-ui/core/Portal';
import { ToastContainer } from 'react-toastify';
import { ModalContainer } from "react-router-modal";

import HomeScreen from "../../components/src/HomeScreen";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AbuseDetector from '../../blocks/abusedetector/src/Abusedetector.web'
import Login from '../../blocks/crm3rdpartyintegration/src/Login.web';
import Middle from '../../blocks/crm3rdpartyintegration/src/Middle.web';
import Crm3rdPartyIntegration from '../../blocks/crm3rdpartyintegration/src/Crm3rdpartyintegration.web';
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  Login: {
    component: Login,
    path: '/login'
  },
  Middle: {
    component: Middle,
    path: '/middle'
  },
  Crm3rdPartyIntegration: {
    component: Crm3rdPartyIntegration,
    path: '/Crm3rdPartyIntegration'
  },
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  AbuseDetector: {
    component: AbuseDetector,
    path: "/AbuseDetector",
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <Portal>
          <ToastContainer
            autoClose={3000}
            position="top-right"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </Portal>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
